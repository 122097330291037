const pathName = typeof window !== 'undefined' ? encodeURIComponent(window.location.host) : '';
const socialWindow = url => {
  let left = (window.innerWidth - 570) / 2;
  let top = (window.innerHeight - 570) / 2;
  let params = 
    'menubar=no,toolbar=no,status=no,width=570,height=570,top=' + top + ',left=' + left;
  window.open(url, 'NewWindow', params);
}

const handleTwitter = link => {
  let url = `https://twitter.com/intent/tweet?url=${pathName}${link}`
  socialWindow(url)
};
const handleLinkedin = (link, title) => {
  let url = `https://www.linkedin.com/shareArticle?mini=true&url=${pathName}${link}&title=${title}`
  socialWindow(url)
};
const handleFacebook = link => {
  let url = `https://www.facebook.com/sharer.php?u=${pathName}${link}`
  socialWindow(url)
};

const shareSocialLink = (type, link, title) => {
  if(type === 'twitter') handleTwitter(link);
  if(type === 'linkedin') handleLinkedin(link, title);
  if(type === 'facebook') handleFacebook(link);
};

export default shareSocialLink;
